import { default as emailsCbl5POUHQEMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/emails.vue?macro=true";
import { default as integrationswCW9VGpJUfMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/integrations.vue?macro=true";
import { default as overviewGqzzPc2lCwMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/overview.vue?macro=true";
import { default as indexnvAlq6bG5tMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsSA5lE7bh2lMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexlKg34x1PbGMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/structures/index.vue?macro=true";
import { default as indexVnAuiaRwTCMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/users/[id]/index.vue?macro=true";
import { default as add6s7arNoWtCMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/users/add.vue?macro=true";
import { default as indexPLLtJtkUNlMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/users/index.vue?macro=true";
import { default as adminxIjves61iwMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin.vue?macro=true";
import { default as accountdTTb1gwNr2Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/account.vue?macro=true";
import { default as entouragevLGngL7xOeMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsOdWhNfLyLNMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthMwrU6F5ieTMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infomyje6umGIGMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationvvSMzBwiodMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdtLh0k4v9HIMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printdgxGCqVgMuMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexpjEzdZIJNrMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/index.vue?macro=true";
import { default as editOlUsvuYyVMMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editys5bsrGaNfMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editmHFiN9ZRdkMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addwYUtqBfC6SMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editL7GxN0PXlqMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addOVpUSRLX7rMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editIDat8rFp5uMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addFtgRGappGmMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editP1t1NHjlLAMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as index6R2qMeJ73qMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/index.vue?macro=true";
import { default as addCv2T1Qadj0Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/add.vue?macro=true";
import { default as historyfV1Ji4T0mhMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/history.vue?macro=true";
import { default as printL9lIpIMimTMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewhDrjXdeLGWMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/overview.vue?macro=true";
import { default as partnerscMuN8B1RjMMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/partners.vue?macro=true";
import { default as statsD1pFgSdrkuMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/stats.vue?macro=true";
import { default as indexm6jNcODnzHMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/structure/index.vue?macro=true";
import { default as subjectsYJ11hwImijMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/structure/subjects.vue?macro=true";
import { default as usersAbd8QG2ev6Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/users.vue?macro=true";
import { default as appblyjwhhaYKMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app.vue?macro=true";
import { default as adminBbI58qTE8vMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/admin.vue?macro=true";
import { default as logineXw4rJ7TA1Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/login.vue?macro=true";
import { default as logoutWlCVJLkKgtMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/logout.vue?macro=true";
import { default as validate_45token3308MrIvFgMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/validate-token.vue?macro=true";
import { default as managerlPgAsGbvvoMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/cgu/manager.vue?macro=true";
import { default as userIsgoyFWPARMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/cgu/user.vue?macro=true";
import { default as cguHw9yC4LohPMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitedGhoNZM9QJMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountARIMsw0tx1Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45tokenSd3ksZmHB5Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45user_45icXbRWiulDa6Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/unknown-user-ic.vue?macro=true";
import { default as unknown_45useruhw8AnJ9cuMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/unknown-user.vue?macro=true";
import { default as indexVpXePgyxn0Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/index.vue?macro=true";
import { default as mentions_45legales1uLDS7vSnIMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteyJNNZshkr0Meta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/politique-confidentialite.vue?macro=true";
import { default as registercuJbkgHJAOMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/register.vue?macro=true";
import { default as statistiquesuUSAyT4poMMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/statistiques.vue?macro=true";
import { default as storiesJz1J1fa7LlMeta } from "/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminxIjves61iwMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/integrations.vue")
  },
  {
    name: overviewGqzzPc2lCwMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewGqzzPc2lCwMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appblyjwhhaYKMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app.vue"),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/account.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupationvvSMzBwiodMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationvvSMzBwiodMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printdgxGCqVgMuMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: indexpjEzdZIJNrMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexpjEzdZIJNrMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/beneficiaries/index.vue")
  },
  {
    name: editOlUsvuYyVMMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editOlUsvuYyVMMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: editys5bsrGaNfMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editys5bsrGaNfMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: editP1t1NHjlLAMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editP1t1NHjlLAMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printL9lIpIMimTMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: overviewhDrjXdeLGWMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewhDrjXdeLGWMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminBbI58qTE8vMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logineXw4rJ7TA1Meta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutWlCVJLkKgtMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45token3308MrIvFgMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguHw9yC4LohPMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitedGhoNZM9QJMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountARIMsw0tx1Meta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45tokenSd3ksZmHB5Meta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user-ic",
    path: "/error/unknown-user-ic",
    meta: unknown_45user_45icXbRWiulDa6Meta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/unknown-user-ic.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45useruhw8AnJ9cuMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexVpXePgyxn0Meta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legales1uLDS7vSnIMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteyJNNZshkr0Meta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registercuJbkgHJAOMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesuUSAyT4poMMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesJz1J1fa7LlMeta || {},
    component: () => import("/build/4f24c62a-bff4-4bb6-8856-4795df2787db/pages/stories.vue")
  }
]